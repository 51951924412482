import React from 'react';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { LiveUpdateProps, LiveUpdateEvent } from './ViewModels';
import { buildFullyQualifiedUrl } from './ControllerActionHelpers/httpHelper';

export function withLiveUpdates<P extends LiveUpdateProps>(
    Component: React.ComponentType<P>
): React.ComponentType<P> {
    return class WithLiveUpdates extends React.Component<P> {
        private eventSource: EventSourcePolyfill | null = null;
        
        componentDidMount() {
            this.setupEventSource();
        }

        componentDidUpdate(prevProps: P) {
            if (prevProps.UpdateType !== this.props.UpdateType || 
                prevProps.OrganizationId !== this.props.OrganizationId) {
                this.cleanup();
                this.setupEventSource();
            }
        }

        componentWillUnmount() {
            this.cleanup();
        }

        private async setupEventSource() {
            const { UpdateType, OrganizationId, auth0, userToken } = this.props;
            try {
                const token = userToken || await auth0.getAccessTokenSilently();
                let liveUpdateUrl = buildFullyQualifiedUrl(`LiveUpdate/Subscribe?updateType=${UpdateType}`);
                
                this.eventSource = new EventSourcePolyfill(
                    liveUpdateUrl,
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    }
                );

                this.eventSource.onmessage = (event: MessageEvent) => {
                    const updateEvent: LiveUpdateEvent = JSON.parse(event.data);
                    //console.log("eventsource.onmessage", updateEvent);
                    if (this.props.OnUpdateReceived) {
                        this.props.OnUpdateReceived(updateEvent);
                    }
                };

                this.eventSource.onerror = (error: Event) => {
                    console.error('SSE error:', error);
                    this.cleanup();
                    
                    setTimeout(() => {
                        this.setupEventSource();
                    }, 5000);
                };
            } catch (error) {
                console.error('Error setting up EventSource:', error);
            }
        }

        private cleanup() {
            if (this.eventSource) {
                this.eventSource.close();
                this.eventSource = null;
            }
        }

        render() {
            return React.createElement(Component, this.props);
        }
    };
}