import React, { useEffect, useState } from 'react';
import './MoveDeviceForm.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DialogFormBase, { IOptionData } from '../DialogFormBase/DialogFormBase';

interface MoveDeviceFormState {
    newOrgId?: string;
    maxAgeDays: string;
    movePatients: boolean;
}

interface MoveDeviceFormProps {
    originalOrgId?: string;
    organizations: Array<vms.IOrganizationBrief>;
}

class MoveDeviceForm extends DialogFormBase<MoveDeviceFormProps, MoveDeviceFormState> {
    constructor(props: MoveDeviceFormProps, state: MoveDeviceFormState) {
        super(props);
        this.state = {
            newOrgId: this.props.originalOrgId,
            maxAgeDays: "0",
            movePatients: false,
        };
    }

    render() {
        return <div className='MoveDeviceForm'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        return (
            <form noValidate>
                {this.buildStandardSelect("model", "Destination Org:", this.state.newOrgId, this.buildOrganizationOptions(), e => this.setState({ newOrgId: e.target.value }), true)}
                {this.buildStandardCheckbox("move-patients", "Move Patients With Device", this.state.movePatients, e => this.toggleMovePatients(e.target.checked), true, false)}
                {
                    this.state.movePatients
                    ? this.buildStandardSelect("max-age-days", "Maximum Scan Age (Days)", this.state.maxAgeDays, this.buildMaxAgeDaysOptions(), e => this.setState({ maxAgeDays: e.target.value}))
                    : null
                }
            </form>
        );
    }

    private buildOrganizationOptions(): Array<IOptionData> {
        let result: Array<IOptionData> = [];
        this.props.organizations.map((org, index, orgs) => {
            result.push(this.buildStandardOption(org.id, org.name ?? org.id));
        });
        return result;
    }

    private buildMaxAgeDaysOptions(): Array<IOptionData> {
        let result: Array<IOptionData> = [];
        for (let i=0; i<=30; i++) {
            result.push(this.buildStandardOption(i.toString()));
        }
        return result;
    }

    private toggleMovePatients(move: boolean) {
        //reset maxAgeDays whenever updating movePatients
        this.setState({
            movePatients: move,
            maxAgeDays: "0",
        });
    }
}

export default MoveDeviceForm;
