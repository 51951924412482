import React, { useEffect, useState } from 'react';
import './SubjectDetail.css';
import * as vms from '../ViewModels';
import { Link } from 'react-router-dom';
import { F } from '../Functions';
import { PatientActions } from '../ControllerActionHelpers/PatientActions';
import SubjectScanCard from '../SubjectScanCard/SubjectScanCard';
import LoadingWidget from '../LoadingWidget/LoadingWidget';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import { LiveUpdateProps, LiveUpdateEvent } from '../ViewModels';
import { withLiveUpdates } from '../withLiveUpdates';

interface SubjectDetailState {
    patientId: string;
    patient?: vms.IPatient;
    fetchingMessage?: string;
}

interface SubjectDetailProps {
    patientId: string;
    userInfo?: vms.EyeBOXUser;
}

type CompositeProps = SubjectDetailProps & LiveUpdateProps;

class SubjectDetail extends React.Component<CompositeProps, SubjectDetailState> {
    constructor(props: CompositeProps, state: SubjectDetailState) {
        super(props);
        this.state = {
            patientId: props.patientId,
            fetchingMessage: undefined,
        };
    }

    render() {
        return <div className='SubjectDetail'>{this.BuildContent()}</div>
    }

    componentDidMount() {
        this.checkFetchPatient();
    }

    componentDidUpdate(prevProps: Readonly<CompositeProps>, prevState: Readonly<SubjectDetailState>, snapshot?: any): void {
        if (this.props.LiveUpdateTrigger != prevProps.LiveUpdateTrigger) {
            this.fetchPatient();
        }
    }

    componentWillUnmount() {
    }

    private checkFetchPatient() {
        let loadingMessage = "Loading " + F.getSubjectLabel() + " Detail...";
        if (this.state.fetchingMessage == loadingMessage) {
            return;
        }
        this.setState({
            patient: undefined,
            fetchingMessage: loadingMessage
        });
        this.fetchPatient();
    }

    private async fetchPatient() {
        var p = await PatientActions.getPatient(this.state.patientId);
        this.setState({
            patient: p,
            fetchingMessage: undefined
        });
    }

    private BuildContent() {
        if (this.state.fetchingMessage) {
            return <LoadingWidget message={this.state.fetchingMessage} fontSize={20} compact={true} />;
        }
        let pages: JSX.Element[] = this.buildSubjectScanCarouselTiles(undefined);
        let slidesPerView = 3;
        if (F.isMobileDevice()) {
            slidesPerView = 1;
        }
        else if (pages.length < 3) {
            slidesPerView = pages.length;
        }
        return (
            <div>
            <div className='row no-gutters'>
                <div className='col-10 col-md-2 back-link'>
                    <Link to={"/organization/subjects"} >
                        <i className='fa fa-chevron-left'></i><span>{"Back to " + F.getSubjectLabel() + " List"}</span>
                    </Link>
                </div>
                <div className='col-10 d-none d-md-block'>
                    { this.CheckIncludeDataRow3(
                        "First:", this.state.patient?.firstName,
                        "Middle:", this.state.patient?.middleInitial,
                        "Last:", this.state.patient?.lastName) }
                    { this.BuildIDAndGenderRow() }
                    { this.CheckIncludeDataRow2(
                        "Alt 1:", this.state.patient?.altId1,
                        "Alt 2:", this.state.patient?.altId2) }
                </div>
            </div>

            { this.CheckIncludeDataRowCompact("First:", this.state.patient?.firstName, "Middle:", this.state.patient?.middleInitial) }
            { this.BuildLastAndDOBOrAgeCompact() }
            { this.CheckIncludeDataRowCompact("ID:", this.state.patient?.eyeBOXPatientId, "Gender:", this.state.patient?.gender) }
            { this.CheckIncludeDataRowCompact("Alt ID 1:", this.state.patient?.altId1, "Alt ID 2:", this.state.patient?.altId2) }

            <div className={'carousel-container' + (F.isMobileDevice() ? " full-width" : "")}>
                <Swiper
                    pagination={{clickable: true}}
                    modules={[Pagination]}
                    slidesPerView={slidesPerView}
                    centeredSlides={true}
                    initialSlide={pages.length - 1}>
                    {pages.map((page, index) => <SwiperSlide key={index}><div className="report-page">{page}</div></SwiperSlide>)}
                </Swiper>
            </div>
        </div>
        );
    }

    private BuildLastAndDOBOrAgeCompact() {
        let varLabel = "";
        let varValue = "";
        if (this.state.patient?.dob) {
            varLabel = "DOB:";
            varValue = F.getDateString(this.state.patient?.dob);
        }
        else if (this.state.patient?.age) {
            varLabel = "Age:";
            varValue = this.state.patient.age.toString() + " years";
        }
        if (F.isNullOrWhitespace(this.state.patient?.lastName)) {
            return this.CheckIncludeDataRowCompact(varLabel, varValue, "", "");
        }
        return this.CheckIncludeDataRowCompact("Last:", this.state.patient?.lastName, varLabel, varValue);
    }

    private BuildIDAndGenderRow() {
        //if we have a dob then this use it
        if (this.state.patient?.dob) {
            return this.CheckIncludeDataRow3(
                "ID:", this.state.patient?.eyeBOXPatientId,
                "Gender:", this.state.patient?.gender,
                "DOB:", F.getDateString(this.state.patient?.dob));
        }
        //otherwise, if we have an age use it
        else if (this.state.patient?.age) {
            return this.CheckIncludeDataRow3(
                "ID:", this.state.patient?.eyeBOXPatientId,
                "Gender:", this.state.patient?.gender,
                "Age:", this.state.patient?.age ? this.state.patient.age.toString() + " years" : "");
        }
        //otherwise, just show ID and Gender
        else {
            return this.CheckIncludeDataRow3(
                "ID:", this.state.patient?.eyeBOXPatientId,
                "Gender:", this.state.patient?.gender,
                "", "");
        }
    }

    private CheckIncludeDataRow3(labelA: string, valueA: string | undefined, labelB: string, valueB: string | undefined, labelC: string, valueC: string | undefined) {
        if (F.isNullOrWhitespace(valueA) && F.isNullOrWhitespace(valueB) && F.isNullOrWhitespace(valueC)) {
            return null;
        }
        return (
            <div className='row'>
                <div className='col-2 data-label'>
                    { labelA }
                </div>
                <div className='col-2 data-value' title={valueA}>
                    { valueA }
                </div>
                <div className='col-2 data-label'>
                    { labelB }
                </div>
                <div className='col-1 data-value' title={valueB}>
                    { valueB }
                </div>
                <div className='col-2 data-label'>
                    { labelC }
                </div>
                <div className='col-3 data-value' title={valueC}>
                    { valueC }
                </div>
            </div>
        );
    }

    private CheckIncludeDataRow2(labelA: string, valueA: string | undefined, labelB: string, valueB: string | undefined) {
        if (F.isNullOrWhitespace(valueA) && F.isNullOrWhitespace(valueB)) {
            return null;
        }
        return (
            <div className='row'>
                <div className='col-2 data-label'>
                    { labelA }
                </div>
                <div className='col-2 data-value' title={valueA}>
                    { valueA }
                </div>
                <div className='col-2 data-label'>
                    { labelB }
                </div>
                <div className='col-5 data-value' title={valueB}>
                    { valueB }
                </div>
            </div>
        );
    }

    private CheckIncludeDataRowCompact(labelA: string, valueA: string | undefined, labelB: string, valueB: string | undefined) {
        if (F.isNullOrWhitespace(valueA) && F.isNullOrWhitespace(valueB)) {
            return null;
        }
        return (
            <div className='row no-gutters d-md-none'>
                <div className='col-2 data-label'>
                    { labelA }
                </div>
                <div className='col-4 data-value' title={valueA}>
                    { valueA }
                </div>
                <div className='col-2 data-label'>
                    { labelB }
                </div>
                <div className='col-4 data-value' title={valueB}>
                    { valueB }
                </div>
            </div>
        );
    }

    private CheckIncludeDataRow1(labelA: string, valueA?: string, labelB?: string, valueB?: string) {
        if (!F.isNullOrWhitespace(labelB)) {
            return this.CheckIncludeDataRowCompact(labelA, valueA, labelB ?? "", valueB);
        }
        if (F.isNullOrWhitespace(valueA)) {
            return null;
        }
        return (
            <div className='row no-gutters d-md-none'>
                <div className='col-5 data-label'>
                    { labelA }
                </div>
                <div className='col-7 data-value' title={valueA}>
                    { valueA }
                </div>
            </div>
        );
    }

    private buildSubjectScanCarouselTiles(allowedCount?: number) {
        const pages: JSX.Element[] = [];
        this.getSortedSessions().map((session, sessionIndex, sessions) => {
            if (allowedCount && sessionIndex >= allowedCount) {
                return;
            }
            session.scans?.map((scan, scanIndex, scans) => {
                pages.push(<SubjectScanCard
                            key={scan.scanID + "-" + this.props.LiveUpdateTrigger}
                            patient={this.state.patient}
                            scan={scan}
                            userInfo={this.props.userInfo}
                            onUpdating={message => {
                                this.onScanUpdating(message);
                            }}
                            onUpdated={() => {
                               this.checkFetchPatient();
                            }}
                       />);
            });
        })
        return pages;
    }

    private getSortedSessions(): Array<vms.ISession> {
        //first extract all of the scans into a temp list so they can be sorted
        let allSessions: Array<vms.ISession> = [];
        this.state.patient?.sessions.map((session, sessionIndex, sessions) => {
            allSessions.push(session);
        });

        //now sort the scans
        let result = allSessions.sort((a, b) => {
            var dtA = new Date(a.sessionDateTime);
            var dtB = new Date(b.sessionDateTime);
            return dtA.valueOf() - dtB.valueOf() ;
        });


        result.forEach((session, index, sessions) => {
            let allScans: Array<vms.IScan> = [];
            session.scans?.map((scan, index, scans) => {
                allScans.push(scan);
            });
            let sortedScans = allScans.sort((a, b) => {
                var dtA = new Date(a.scanDateTime);
                var dtB = new Date(b.scanDateTime);
                return dtB.valueOf() - dtA.valueOf() ;
            });
            session.scans = sortedScans;
        });
        return result;
    }

    private onScanUpdating(message: string) {
        this.setState({
            fetchingMessage: message
        });
    }
}

export default withLiveUpdates(SubjectDetail);
