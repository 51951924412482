import React, { useEffect, useState } from 'react';
import './ScanCardViewBase.css';
import * as vms from '../ViewModels';
import ApplicationModal from '../ApplicationModal/ApplicationModal';
import ApplicationToast from '../ApplicationToast/ApplicationToast';
import { PatientActions } from '../ControllerActionHelpers/PatientActions';
import { F } from '../Functions';
import { getLastFilename } from '../ControllerActionHelpers/httpHelper';
import PlayOrDownloadVideoForm from '../PlayOrDownloadVideoForm/PlayOrDownloadVideoForm';

export interface ScanCardViewBaseState {
}

export interface ScanCardViewBaseProps {
    scan: vms.IScan;
}

export interface ScanActionOptions {
    includePdf: boolean,
    includeDonwloadVideo: boolean,
    downloadVideoText?: string,
    playVideoText?: string,
}

export abstract class ScanCardViewBase<P extends ScanCardViewBaseProps, S extends ScanCardViewBaseState> extends React.Component<P, S> {
    protected noPDFMessage = "No PDF is available for this scan.";
    protected noVideoMessage = "No Video is available for this scan.";

    protected archiveContentTypes = [
        vms.ContentTypes.PDF,
        vms.ContentTypes.Form,
        vms.ContentTypes.Meta,
        vms.ContentTypes.Comments,
        vms.ContentTypes.Metrics,
        vms.ContentTypes.Video,
    ];

    protected pdfContentType = [
        vms.ContentTypes.PDF
    ];

    constructor(props: P) {
        super(props);
    }

    protected scanHasPDF(): boolean {
        return this.scanHasContentType(vms.ContentTypes.PDF);
    }

    protected scanHasVideo(): boolean {
        return this.scanHasContentType(vms.ContentTypes.Video);
    }

    protected scanHasAnyFiles(): boolean {
        return this.props.scan.files.length > 0;
    }

    protected scanHasContentType(contentType: vms.ContentTypes) {
        for (var file of this.props.scan.files) {
            if (file.contentType == contentType) {
                return true;
            }
        }
        return false;
    }

    protected getTodayOrNDaysAgo() {
        const scan = this.props.scan;
        let date1 = new Date(scan.scanDateTime);
        let date2 = new Date();

        // Normalize the dates to remove time components
        const startOfDay1 = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate());
        const startOfDay2 = new Date(date2.getFullYear(), date2.getMonth(), date2.getDate());

        // Calculate the difference in time (in milliseconds)
        const timeDifference = startOfDay2.getTime() - startOfDay1.getTime();

        // Convert the time difference from milliseconds to days
        const dayDifference = Math.round(timeDifference / (1000 * 60 * 60 * 24));

        // Determine the message based on the day difference
        if (dayDifference === 0) {
            return "Today";
        } else if (dayDifference === 1) {
            return "Yesterday";
        } else {
            return `${Math.abs(dayDifference)} days ago`;
        }
    }

    protected buildScanActionButtons(options: ScanActionOptions) {
        let hasPdf = this.scanHasPDF();
        let hasVideo = this.scanHasVideo();
        let hasAny = this.scanHasAnyFiles();
        return (
            <div className='d-block scan-actions'>
                {
                    options.includePdf
                    ? hasPdf
                        ? (
                            <div className='row no-gutters'>
                                <a className='comment-action' onClick={e => {
                                    this.viewOrDownloadScan(false, this.pdfContentType);
                                }}>
                                    <span className='action'><i className='fa fa-up-right-from-square' />View report in new tab</span>
                                </a><br />
                                <a className='comment-action' onClick={e => {
                                    this.viewOrDownloadScan(true, this.pdfContentType);
                                }}>
                                    <span className='action'><i className='fa fa-file-pdf' />Download as PDF</span>
                                </a>
                            </div>
                        )
                        : <span className='action'><i className='fa fa-ban' />Report PDF unavailable</span>
                    : null
                }
                {
                    hasVideo
                        ? (
                            <div className='row no-gutters'>
                                <a className='comment-action' onClick={e => {
                                    this.playOrDownloadVideo();
                                }}>
                                    <span className='action'><i className='fa fa-video' />{(options.playVideoText ?? "Download Video")}</span>
                                </a>
                            </div>
                        )
                        : null
                }
                {
                    hasAny
                        ? (
                            <div className='row no-gutters'>
                                <a className='comment-action' onClick={e => {
                                    this.downloadArchive();
                                }}>
                                    <span className='action'><i className='fa fa-download' />Download archive</span>
                                </a>
                            </div>
                        )
                        : null
                }
            </div>
        )
    }

    private async downloadArchive() {
        const abortController = new AbortController();
        ApplicationModal.showCancelableSpin("Generating archive...", abortController);
        let scan = this.props.scan;
        return await PatientActions.getScanArchive(scan.patientId, scan.scanID, abortController.signal)
            .then(file => {
                var url = URL.createObjectURL(file);
                let a = document.createElement("a") as HTMLAnchorElement;
                a.href = url;
                a.download = scan.scanID + ".zip";
                a.click();
                URL.revokeObjectURL(url);
                ApplicationModal.close();
            })
            .catch(e => {
                if (e == ApplicationModal.AbortDownloadReason) {
                    return;
                }
                F.debugEvent(e);
                let errorMsg = "An unexpected error occurred while attempting to download the archive.";
                if (e && e.cause) {
                    if (e.cause.message) {
                        errorMsg = e.cause.message;
                    }
                    else if (typeof (e.cause) == "string") {
                        errorMsg = e.cause;
                    }
                }
                if (errorMsg == "No scan files were found") {
                    errorMsg = "The requested scan could not be found. The scan may have been moved or deleted from the server.";
                }
                ApplicationModal.showError(errorMsg);
            });
    }

    private async viewOrDownloadScan(download: boolean, downloadTypes: Array<vms.ContentTypes>) {
        let scan = this.props.scan;
        if (!this.scanHasPDF() && downloadTypes.length == 1) {
            ApplicationToast.Show(this.noPDFMessage);
            return;
        }

        let waitMsg = (download
            ? "Downloading"
            : "Retrieving") + " scan...this may take a moment.";
        const abortController = new AbortController();
        ApplicationModal.showCancelableSpin(waitMsg, abortController);
        return await PatientActions.getScanFiles(scan.patientId, [scan.scanID], downloadTypes, abortController.signal)
            .then(file => {
                var url = URL.createObjectURL(file);
                let a = document.createElement("a") as HTMLAnchorElement;
                a.href = url;
                if (download) {
                    if (downloadTypes.length == 1) {
                        a.download = scan.scanID + ".pdf";
                    }
                    else {
                        a.download = scan.scanID + ".zip";
                    }
                }
                else {
                    a.target = "_blank";
                }
                a.click();
                URL.revokeObjectURL(url);
                ApplicationModal.close();
            })
            .catch(e => {
                if (e == ApplicationModal.AbortDownloadReason) {
                    return;
                }
                F.debugEvent(e);
                let errorMsg = "An unexpected error occurred while attempting to " + (download ? "download" : "retrieve") + " the scan.";
                if (e && e.cause) {
                    if (e.cause.message) {
                        errorMsg = e.cause.message;
                    }
                    else if (typeof (e.cause) == "string") {
                        errorMsg = e.cause;
                    }
                }
                if (errorMsg == "No scan files were found") {
                    errorMsg = "The requested scan could not be found. The scan may have been moved or deleted from the server.";
                }
                ApplicationModal.showError(errorMsg);
            });
    }

    private async playOrDownloadVideo() {
        let scan = this.props.scan;
        if (!this.scanHasVideo()) {
            ApplicationToast.Show(this.noVideoMessage);
            return;
        }

        const abortController = new AbortController();
        ApplicationModal.showCancelableSpin("Retrieving video", abortController);
        return await PatientActions.getScanFiles(scan.patientId, [scan.scanID], [vms.ContentTypes.Video], abortController.signal)
            .then(file => {
                var url = URL.createObjectURL(file);
                //expect the form to schedule deletion of the url
                ApplicationModal.showForm(<PlayOrDownloadVideoForm
                    videoFilename={scan.scanID + ".mp4"}
                    videoUrl={url}
                    reportID={scan.scanID}
                    reportDateTime={scan.scanDateTime}
                    videoSizeBytes={file.size}
                />, "Video Ready", undefined, undefined, "CLOSE", true);
            })
            .catch(e => {
                if (e == ApplicationModal.AbortDownloadReason) {
                    return;
                }
                F.debugEvent(e);
                let errorMsg = "An unexpected error occurred while attempting to retrieve the video.";
                if (e && e.cause) {
                    if (e.cause.message) {
                        errorMsg = e.cause.message;
                    }
                    else if (typeof (e.cause) == "string") {
                        errorMsg = e.cause;
                    }
                }
                if (errorMsg == "No video files were found") {
                    errorMsg = "The requested video could not be found. The video may have been moved or deleted from the server.";
                }
                ApplicationModal.showError(errorMsg);
            });
    }

    //todo: add shared code here
}

export default ScanCardViewBase;
