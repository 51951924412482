import React, { useEffect, useState } from 'react';
import './CreateDeviceForm.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import DialogFormBase, { IOptionData } from '../DialogFormBase/DialogFormBase';
import { F } from '../Functions';

interface CreateDeviceFormState {
    device: vms.LicensedDevice;
    currentPage: DevicePages;
}

interface CreateDeviceFormProps {
    initialValues: vms.IDevice;
    isCreating: boolean;
    configuration?: vms.IWebsiteConfiguration;
}

enum DevicePages {
    Summary,
    Licensing,
}

class CreateDeviceForm extends DialogFormBase<CreateDeviceFormProps, CreateDeviceFormState> {
    constructor(props: CreateDeviceFormProps, state: CreateDeviceFormState) {
        super(props);
        this.state = {
            device: new vms.LicensedDevice(this.props.initialValues),
            currentPage: DevicePages.Summary
        } ;
    }

    render() {
        return <div className='CreateDeviceForm'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        return (
            <form noValidate>

                <div className="tab-controls">
                    <div
                        className={`tab-item ${this.state.currentPage === DevicePages.Summary ? 'active' : ''}`}
                        onClick={() => this.setState({ currentPage: DevicePages.Summary })}
                    >
                        Summary
                    </div>
                    <div
                        className={`tab-item ${this.state.currentPage === DevicePages.Licensing ? 'active' : ''}`}
                        onClick={() => this.setState({ currentPage: DevicePages.Licensing })}
                    >
                        Licensing
                    </div>
                </div>
                <hr className='tab-item-bottom'></hr>
                <div className='tab-content'>
                {
                    this.state.currentPage == DevicePages.Summary
                    ? this.buildSummaryContent()
                    : this.buildLicensingContent()
                }
                </div>
            </form>
        );
    }

    private buildSummaryContent() {
        let result: Array<any> = [];
        result.push(this.buildStandardInput("eyebox-device-id", "Device ID", this.state.device.eyeBOXDeviceID, e => this.setDeviceState({ eyeBOXDeviceID: e.target.value }), true, undefined, undefined, !this.props.isCreating));
        result.push(this.buildStandardSelect("model", "Model", this.state.device.model, this.buildModelOptions(), e => this.setDeviceState({ model: e.target.value }), true));
        result.push(this.buildStandardInput("serial-number", "Serial Number", this.state.device.serialNumber, e => this.setDeviceState({ serialNumber: e.target.value }), true));
        result.push(this.buildStandardInput("software-version", "Software", this.state.device.softwareVersion, e => this.setDeviceState({ softwareVersion: e.target.value }), true, () => false, undefined, true));
        result.push(this.buildStandardDatePicker("placed-in-service", "Service Start Date", this.state.device.placedInService, e => this.setPlacedInService(e.target.value), true, () => false));
        return result;
    }

    private buildLicensingContent() {
        let result: Array<any> = [];
        result.push(this.buildStandardDatePicker("license-expiration", "Expiration Date", this.state.device.licenseExpiration, e => this.setLicenseExpiration(e.target.value), true, () => false));
        result.push(this.buildStandardCheckbox("allowExportOfMetrics", "Export Metrics", this.state.device.allowExportOfMetrics, e => this.setAllowExportOfMetrics(e.target.checked), true));
        var offlineLicensingLabel = "Offline Licensing";
        if (this.state.device.allowOfflineLicensing) {
            offlineLicensingLabel += " (Must be manually applied)";
        }
        result.push(this.buildStandardCheckbox("allowOfflineLicensing", offlineLicensingLabel, this.state.device.allowOfflineLicensing, e => this.setAllowOfflineLicensing(e.target.checked), true));
        //result.push(this.buildStandardInput("license-code", "License Code", this.state.device.licenseCode, () => {}, false, () => false, undefined, true));
        return result;
    }

    private setDeviceState(props: any) {
        var device = this.state.device;
        Object.assign(device, props);
        this.setState({device}, this.reportState);
    }

    private setAllowExportOfMetrics(checked: boolean) {
        this.setDeviceState({allowExportOfMetrics: checked})
    }

    private setAllowOfflineLicensing(checked: boolean) {
        this.setDeviceState({allowOfflineLicensing: checked})
    }

    private reportState() {
        // console.log("before", this.state.device.licenseOptions);
        // this.state.device.buildOptionsFromFeatures();
        // console.log("after", this.state.device.licenseOptions);
    }

    private tryParseDate(s: string) {
        if (!F.isNullOrWhitespace(s)) {
             return new Date(s);
        }
        return undefined;
    }

    private setPlacedInService(s: string) {
        this.setDeviceState({placedInService: this.tryParseDate(s)});
    }

    private setLicenseExpiration(s: string) {
        this.setDeviceState({licenseExpiration: this.tryParseDate(s)});
    }

    private buildModelOptions(): Array<IOptionData> {
        let result: Array<IOptionData> = [];
        result.push(this.buildStandardOption("", "--- Select ---"));
        this.props.configuration?.deviceModels?.map((deviceModel, index, deviceModels) => {
            result.push(this.buildStandardOption(deviceModel.name));
        });
        return result;
    }
}

export default CreateDeviceForm;
