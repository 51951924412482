import React, { useEffect, useState } from 'react';
import './OrganizationSubjects.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import { doGet, doPost } from '../ControllerActionHelpers/httpHelper';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { PatientActions } from '../ControllerActionHelpers/PatientActions';
import { F } from '../Functions';
import { Debounce, IThrottledRequest } from '../ControllerActionHelpers/Debounce';
import ApplicationModal from '../ApplicationModal/ApplicationModal';
import ApplicationToast from '../ApplicationToast/ApplicationToast';
import SubjectCard from '../SubjectCard/SubjectCard';
import LoadingWidget from '../LoadingWidget/LoadingWidget';
import { PageActions } from '../PageActions';
import DataExportCriteriaForm from '../DataExportCriteriaForm/DataExportCriteriaForm';
import { OrganizationActions } from '../ControllerActionHelpers/OrganizationActions';
import { ControllerActions } from '../ControllerActionHelpers/ControllerActions';
import { withLiveUpdates } from '../withLiveUpdates';

interface OrganizationSubjectsState {
    filter: string,
    onFilterChange: (filter: string) => void,
    patients?: Array<vms.IPatient>,
    availableCount?: number,
    isFetching?: boolean,//keeps track of whether we are waiting for a response
    stillLoading?: boolean,//keeps track of whether we are finished handling pending requests
}

interface OrganizationSubjectsProps {
    filter: string,
    onFilterChange: (filter: string) => void,
    userInfo?: vms.EyeBOXUser,
}

type CompositeProps = OrganizationSubjectsProps & vms.LiveUpdateProps;

class OrganizationSubjects extends React.Component<CompositeProps, OrganizationSubjectsState> {

    constructor(props: CompositeProps, state: OrganizationSubjectsState) {
        super(props);
        this.state = {
            filter: props.filter,
            onFilterChange: props.onFilterChange,
            patients: [],
        };
    }

    render() {
        return <div className='OrganizationSubjects'>{
            this.BuildContent()
        }</div>
    }

    componentDidMount() {
        if (this.props.userInfo?.HasRole(vms.EyeBOXUserRole.Admin)) {
            PageActions.BeginUpdate();
            try {
                PageActions.Add("Export Data", this.onExportData.bind(this));
            }
            finally {
                PageActions.EndUpdate();
            }
        }
        setTimeout(this.getPatients.bind(this), 0);
    }

    componentWillUnmount() {
    }

    componentDidUpdate(prevProps: Readonly<CompositeProps>, prevState: Readonly<OrganizationSubjectsState>, snapshot?: any): void {
        if (this.props.LiveUpdateTrigger != prevProps.LiveUpdateTrigger || this.props.UpdateType != prevProps.UpdateType || this.props.DocumentId != prevProps.DocumentId) {
            for (let patient of this.state.patients || []) {
                if (patient.id == this.props.DocumentId) {
                    //need to refresh the patient
                    PatientActions
                        .getPatient(patient.id)
                        .then(p => {
                            this.includePatient(p);
                        })
                        .catch(reason => {
                            console.log("Unable to fetch updated patient", reason);
                        });
                    return;
                }
            }
            // Either the patient is on a page not yet fetched or it would
            // have been returned in an earlier page. Compare its lastScanDate
            //to others in the list to see if it can be inserted.

        }
    }

    private includePatient(patient: vms.IPatient) {
        //do not attempt this if we have not retrieved any patients
        let existing = this.state.patients;
        if (!existing || existing.length == 0) {
            return;
        }
        let updated = false;
        let removed = false;
        //try to find the patient in the existing patients
        for (let i=0; i<existing.length; i++) {
            if (existing[i].id == patient.id) {
                if (!patient.lastScanDate && !existing[i].lastScanDate || patient.lastScanDate == existing[i].lastScanDate) {
                    //the index should not change so just replace the existing patient with the new one
                    existing[i] = patient;
                    updated = true;
                }
                else if (patient.lastScanDate) {//} && !existing[i].lastScanDate) {
                    existing.splice(i, 1);
                    removed = true;
                }
                else if (!patient.lastScanDate && existing[i].lastScanDate) {
                    //this case doesn't make sense since we do not allow deleting scans
                    console.log("A scan seems to have been removed from patient " + patient.id);
                    return;
                }
                break;
            }
        }
        if (removed) {
            existing = this.insertPatientByLastScanDate(existing, patient, true);
            updated = true;
        }
        else if (!updated) {
            //if neither updated nor removed we need to try inserting (not adding)
            existing = this.insertPatientByLastScanDate(existing, patient, false);
            updated = true;
        }
        if (updated) {
            this.setState({patients: existing});
        }
    }

    private insertPatientByLastScanDate(patients: Array<vms.IPatient>, patient: vms.IPatient, allowAdd: boolean): Array<vms.IPatient> {
        //this method is only called when we have a lastScanDate on the updated patient
        let newLastScanDate = patient.lastScanDate!;
        for (let i=0; i< patients.length; i++) {
            if (!patients[i].lastScanDate || patients[i].lastScanDate! < newLastScanDate) {
                patients.splice(i, 0, patient);
                return patients;
            }
        }
        //we did not find a place to insert the patient, so add it to the end if allowed
        if (allowAdd) {
            patients.push(patient);
        }
        else {
            //we only allow insert for items that were not found in the existing list
        }
        return patients;
    }

    private BuildContent() {
        let scanTypes = this.determineScanTypes();

        return (<div className=''>
            <div className='detail-header d-block'>
                <div className='full-width'>
                    <div className="">
                        <input
                            type="text"
                            className='full-width'
                            value={this.state.filter}
                            onChange={e => this.onPatientFilterChange(e.target.value)}
                            placeholder={"Search " + F.getSubjectsLabel().toLowerCase() + "..."} />
                    </div>
                </div>
            </div>
            <br/>

            <div className=''>
            <div className='detail-body table-rounded-container d-none d-md-block'>                
                { this.buildSubjectListHeader(scanTypes) }
                {this.state.patients?.length ? <hr className='table-header-separator'/> : <span/>}                    
                {
                    this.state.patients
                        ? this.state.patients?.map((patient, index, patients) => {
                            return (
                                <Link className='patient-link' key={patients.length + "-" + index} to={"/organization/subjectdetail?" + patient.id}>
                                { this.buildSubjectListRow(scanTypes, patient, index) }                                    
                                </Link>
                        )})
                        : null
                }
            </div>
            <div className='detail-body d-md-none'>
            {
                    this.state.patients
                        ? this.state.patients?.map((patient, index, patients) => {
                            return (<SubjectCard
                                        key={patients.length + "-" + index}
                                        patient={patient}
                                        isContributor={this.props.userInfo?.HasRole(vms.EyeBOXUserRole.Contributor) ?? false} />
                        )})
                        : null
                }
            </div>
        </div>

            {this.buildMorePatientsButton()}
        </div>
        );
    }

    private buildSubjectListHeader(scanTypes: vms.ScanTypes) {
        return scanTypes.isAll()
        ? this.buildCompositeListHeader()
        : scanTypes.cannabis
        ? this.buildCannabisListHeader()
        : this.buildConcussionListHeader();
    }

    private buildCompositeListHeader() {
        return (<div className='row full-width table-header no-gutters'>
            <div className='col-3'>{F.getSubjectLabel()}</div>
            <div className='col-1'>Age</div>
            <div className='col-1'>Gender</div>
            <div className='col-2'>ID</div>
            <div className='col-3'>Last Scan</div>
            <div className='col-2'>Result</div>
        </div>)
    }

    private buildCannabisListHeader() {
        return (<div className='row full-width table-header no-gutters'>
            <div className='col-2'>Age</div>
            <div className='col-2'>Gender</div>
            <div className='col-4'>Last Scan</div>
            <div className='col-4'>Result</div>
        </div>)
    }

    private buildConcussionListHeader() {
        return (<div className='row full-width table-header no-gutters'>
            <div className='col-8 col-md-4'>{F.getSubjectLabel()}</div>
            <div className='col-4 col-md-2'>Age</div>
            <div className='col-2'>Gender</div>
            <div className='col-2'>ID</div>
            <div className='col-2'>Last Scan</div>
        </div>)
    }

    private buildSubjectListRow(scanTypes: vms.ScanTypes, patient: vms.IPatient, index: number) {
        return scanTypes.isAll()
        ? this.buildCompositeListRow(patient, index)
        : scanTypes.cannabis
        ? this.buildCannabisListRow(patient, index)
        : this.buildConcussionListRow(patient, index);
    }

    private buildCompositeListRow(patient: vms.IPatient, index: number) {
        let mostRecentScan = this.getMostRecentScan(patient);
        return (<div key={index} className='row patient-row no-gutters' >
            <div className='col-3 data-cell'>
                    <i className='fa fa-hospital-user'/>
                    {F.buildPatientNameOrAltIDs(patient)}
            </div>
            <div className='col-1 data-cell' >{patient.age ? patient.age + " years" : "N/A"}</div>
            <div className='col-1 data-cell' >{patient.gender}</div>
            <div className='col-2 data-cell' >{patient.eyeBOXPatientId}</div>
            <div className='col-3 data-cell' >{ `${F.getDateString(patient.lastScanDate)} (${mostRecentScan ? mostRecentScan.scanID : "Scan not found"})` }</div>
            <div className='col-2 data-cell' >{ (mostRecentScan ? this.getLastScanResult(mostRecentScan) : "Scan not found") }</div>
        </div>)
    }

    private buildCannabisListRow(patient: vms.IPatient, index: number) {
        let mostRecentScan = this.getMostRecentScan(patient);
        return (<div key={index} className='row patient-row no-gutters' >
            <div className='col-2 data-cell'>
                    <i className='fa fa-hospital-user'/>
                    {patient.age ? patient.age + " years" : "N/A"}
            </div>
            <div className='col-2 data-cell' >{patient.gender}</div>
            <div className='col-4 data-cell' >
                { `${F.getDateString(patient.lastScanDate)} (${mostRecentScan ? mostRecentScan.scanID : "Scan not found"})` }
            </div>
            <div className='col-4 data-cell' >
                { (mostRecentScan ? this.getLastScanResult(mostRecentScan) : "Scan not found") }
            </div>
        </div>)
    }

    private getMostRecentScan(patient: vms.IPatient) {
        var lastScan: vms.IScan | undefined = undefined;
        for (var session of patient.sessions) {
            if (!session.scans) {
                continue;
            }
            for (var scan of session.scans) {
                if (!lastScan ||  lastScan.scanDateTime < scan.scanDateTime) {
                    lastScan = scan;
                }
            }
        }
        return lastScan;
    }

    private getLastScanResult(scan: vms.IScan) {
        if (scan.normality == vms.Normality.Undefined) {
            return "Could not be computed";
        }
        return `${(scan.cannabis ?? 0.0).toFixed(2)} (${vms.Normality[scan.normality]})`;
    }

    private buildConcussionListRow(patient: vms.IPatient, index: number) {
        return (<div key={index} className='row patient-row no-gutters' >
            <div className='col-8 col-md-4 data-cell'>
                    <i className='fa fa-hospital-user'/>
                    {F.buildPatientNameOrAltIDs(patient)}
            </div>
            <div className='col-4 col-md-2 data-cell' >{patient.age}</div>
            <div className='col-2 data-cell' >{patient.gender}</div>
            <div className='col-2 data-cell' >{patient.eyeBOXPatientId}</div>
            <div className='col-2 data-cell' >{F.getDateString(patient.lastScanDate)}</div>
        </div>)
    }

    private determineScanTypes(): vms.ScanTypes {
        let result = new vms.ScanTypes();
        if (this.state.patients) {
            for (let patient of this.state.patients) {
                let patientScanTypes = F.determinePatientScanTypes(patient);
                result.cannabis ||= patientScanTypes.cannabis;
                result.concussion ||= patientScanTypes.concussion;
                if (result.isAll()) {
                    break;
                }
            }
        }
        return result;
    }

    private onPatientFilterChange(newFilter: string) {
        this.state.onFilterChange(newFilter);
        this.setState({
            patients: [],
            availableCount: undefined,
            filter: newFilter
        });
        this.schedulePatientRequest(newFilter);
    }

    static readonly PatientRequestName: string = "patient-filter-request";

    private schedulePatientRequest(filter: string) {        
        Debounce.AddRequest({
            name: OrganizationSubjects.PatientRequestName,
            timeoutMs: 400,
            action: (request: IThrottledRequest) => {
                if (request.name != OrganizationSubjects.PatientRequestName) {
                    console.log("Request name does not match allowed name.", request.name, OrganizationSubjects.PatientRequestName);
                    return;
                }
                console.log("Debounce executed", request.name, request.tag);
                this.checkApplyFilter(request.tag);
            },
            tag: filter,
        });
    }

    private checkApplyFilter(filter?: string) {
        let applyFilter = false;
        if (F.isNullOrWhitespace(filter) && F.isNullOrWhitespace(this.state.filter)) {
            applyFilter = true;
        }
        else if (filter == this.state.filter) {
            applyFilter = true;
        }
        if (applyFilter) {            
            this.getPatients(0);
        }
    }

    private async getPatients(startIndex: number | undefined) {
        if (this.state.isFetching) {
            //wait for the existing fetch to return before sending another
            return;
        }
        let fetchedCount = this.state.patients?.length ?? 0;
        if (undefined == startIndex) {
            startIndex = 0;
        }
        if (startIndex < fetchedCount) {
            return;
        }

        this.setState({isFetching: true, stillLoading: true});
         PatientActions.enumerate(this.state.filter, startIndex)
            .then(response => {
                this.setState({isFetching: false});
                if (response.filter != this.state.filter && !(F.isNullOrWhitespace(response.filter) && F.isNullOrWhitespace(this.state.filter))) {
                    console.log("Returned filter does not match the existing filter");
                    this.onPatientFilterChange(this.state.filter);
                    return;
                }
                var mergedPatients = this.mergeResponseWithExistingPatients(response);
                this.setState({
                    patients: mergedPatients,
                    availableCount: response.available,
                    stillLoading: false,
                });
            })
            .catch(error => {
                this.setState({isFetching: false, stillLoading: false});
                console.log(error);
            });
    }

    private mergeResponseWithExistingPatients(response: vms.IPatientResponse): Array<vms.IPatient> {
        //if there was no prior response then use the returned records
        if (!this.state.patients || this.state.patients.length == 0) {
            return response.records;
        }
        //if the prior response matches the current response use the returned records (might be updated)
        if (this.state.patients.length == response.records.length && 0 == response.startIndex) {
            return response.records;
        }
        let result: Array<vms.IPatient> = F.clone(this.state.patients);
        if (result.length == response.startIndex) {
            //console.log("received auxiliary page");
            result = result.concat(response.records);
        }
        else {
            //todo: replace a page? populate dummy rows? nothing?
            console.log("StartIndex: " + response.startIndex + ", ExistingCount: " + result.length);
        }
        return result;
    }

    private buildMorePatientsButton() {
        if (this.state.stillLoading) {
            return <LoadingWidget message={"Loading " + F.getSubjectsLabel() + "..."} fontSize={20} compact={true} />;
        }
        let fetchedCount = this.state.patients?.length ?? 0;
        if (fetchedCount < (this.state.availableCount ?? 0)) {
            return (
                <div className='row full-width loaded-display'>
                    <div className='centered'>
                        { "Showing " + fetchedCount + " of " + (this.state.availableCount ?? 0) }
                    </div>
                    <a className='rounded-button-blue' href='#' onClick={() => this.getPatients(fetchedCount)}>
                        LOAD MORE
                    </a>
                </div>
            );
        }
        return <br/>;
    }

    private async onExportData() {
        var form: any;
        var doExport = await ApplicationModal.showForm(<DataExportCriteriaForm
            ref={e => { form = e ?? form; }}
        />, "Export Data", (form) => {
            return DataExportCriteriaForm.OnValidate(form);
        });
        if (doExport) {
            const abortController = new AbortController();
            ApplicationModal.showCancelableSpin("Retrieving data for export...", abortController);
            await OrganizationActions.getExportData(form.state, abortController.signal)
            .then(file => {
                var url = URL.createObjectURL(file);
                let a = document.createElement("a") as HTMLAnchorElement;
                a.href = url;
                a.download = form.state.exportName + ".csv";
                a.click();
                URL.revokeObjectURL(url);
                ApplicationModal.close();
            })
            .catch(reason => {
                if (reason == ApplicationModal.AbortDownloadReason) {
                    return;
                }
                ControllerActions.reportError(reason);
            });
        }
    }

}


export default withLiveUpdates(OrganizationSubjects);
