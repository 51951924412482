import React, { useEffect, useState } from 'react';
import './Organization.css';
import * as vms from '../ViewModels'
import { Navigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { PageActions } from '../PageActions'
import { PageScroller, ScrollPosition } from '../PageScroller'
import { OrganizationActions } from "../ControllerActionHelpers/OrganizationActions"
import { ControllerActions } from '../ControllerActionHelpers/ControllerActions';
import ApplicationTitle from '../ApplicationTitle/ApplicationTitle';
import { F } from "../Functions";
import OrganizationResources from '../OrganizationResources/OrganizationResources';
import OrganizationSubjects from '../OrganizationSubjects/OrganizationSubjects';
import SubjectDetail from '../SubjectDetail/SubjectDetail';
import SecondaryTitle from '../SecondaryTitle/SecondaryTitle';
import OrganizationUsers from '../OrganizationUsers/OrganizationUsers';
import OrganizationDevices from '../OrganizationDevices/OrganizationDevices';
import { ConfigurationActions } from '../ControllerActionHelpers/ConfigurationActions';
import { withLiveUpdates } from '../withLiveUpdates';
import { LiveUpdateProps } from '../ViewModels';
import { event } from 'jquery';

interface OrganizationState {
    userInfo?: vms.EyeBOXUser;
    org?: vms.IOrganization;
    news?: Array<vms.INews>;
    patients?: Array<vms.IPatient>;
    availablePatientCount?: number;
    viewMode: OrganizationViewMode;
    users?: Array<vms.IEyeBOXUser>;
    configuration?: vms.IWebsiteConfiguration;
    liveUpdateTrigger: number;
    lastUpdatedSubjectId: string;
}

interface OrganizationProps extends vms.AuthenticatedUserProps {
    needFetch?: boolean;
    viewMode: OrganizationViewMode;
}

export enum OrganizationViewMode {
    Subjects, Users, Reports, Devices, Resources, SubjectDetail
}

class Organization extends React.Component<OrganizationProps, OrganizationState> {
    private needFetch = true;
    private lastViewMode?: OrganizationViewMode;
    private lastFilter?: string;
    private fetchedPatientCount?: number;
    private patientFilter: string = "";

    constructor(props: OrganizationProps, state: OrganizationState) {
        super(props);
        let viewMode = props.viewMode;
        let user = new vms.EyeBOXUser(props.userInfo);
        this.state = {
            userInfo: user,
            viewMode: viewMode,
            liveUpdateTrigger: 0,
            lastUpdatedSubjectId: "",
        };
    }

    render() {        
        if (window.location.pathname.toLowerCase() == "/organization") {
            if (this.state.userInfo?.CanViewPatients()) {
                return <Navigate to={"/organization/subjects"} />;
            }
            else if (this.state.userInfo?.CanViewUsersAndDevices())  {
                return <Navigate to={"/organization/users"} />;
            }
            else if (this.state.userInfo?.HasRole(vms.EyeBOXUserRole.Super) && F.isNullOrWhitespace(this.state.userInfo?.currentOrganizationId)) {
                return <Navigate to={"/super"} />;
            }
            return (
                <div className='space-evenly row'>
                    You are not authorized to view data. Please contact your administrator.
                </div>
            );
        }
        ApplicationTitle.Set({
            content: this.BuildTitleContent()
        });
        SecondaryTitle.Set({
            content: this.BuildTitleContent()
        })
         return (
            <div className='Organization'>
                {this.BuildOrganizationHeader()}
                {this.BuildContent()}
            </div>)
    }

    componentDidMount() {
        //this.checkFetchNews();
        PageActions.BeginUpdate();
        try {
            //PageActions.Add("Refresh Organization", this.getSnapshot.bind(this));
            //PageActions.Add("Toast", () => ApplicationToast.Show("This is a toast"));
        }
        finally {
            PageActions.EndUpdate();
            setTimeout(this.getSnapshot.bind(this), 0);
            setTimeout(this.getWebsiteConfiguration.bind(this), 0);
        }
        PageScroller.Listen((position, offset, max) => {
            if (position == ScrollPosition.End || (offset / max) > 0.75 ||
                (!PageScroller.IsVisible()
                    && (this.state.availablePatientCount ?? 0) > (this.fetchedPatientCount ?? 0)
                )) {
                //try to get another page of patients
                if (this.getViewMode() == OrganizationViewMode.Subjects) {
                    this.doFetch();
                }
            }
        });
    }

    componentWillUnmount() {
        PageActions.Clear();
        ApplicationTitle.Clear();
        PageScroller.Clear();
    }

    private async getSnapshot() {
        OrganizationActions.get()
            .then(org => {
                this.needFetch = true;
                this.lastFilter = undefined;
                this.fetchedPatientCount = undefined;
                this.setState({ org, patients: undefined, availablePatientCount: undefined });
            })
            .catch(ControllerActions.reportError);
    }

    private async getWebsiteConfiguration() {
        var config = await ConfigurationActions.getConfiguration();
        this.setState({
            configuration: config
        });
    }


    private doFetch() {
        this.lastViewMode = this.getViewMode();
        switch (this.lastViewMode) {
            case OrganizationViewMode.Reports:
                //this.getReports();
                return "Loading Reports...";
            case OrganizationViewMode.Users:
                window.setTimeout(this.getUsers.bind(this));
                return "Loading Users...";
        }
    }

    private async getUsers() {
        if (this.getViewMode() != OrganizationViewMode.Users) {
            console.log("Skipping user fetch");
            return;
        }
        //todo: confirm reading users from org is sufficient now
        this.setState({ users: this.state.org?.users ?? [] });
        // console.log("Fetching users");
        // this.setState({ users: undefined });
        // OrganizationActions.getUsers()
        //     .then(users => {
        //         this.setState({ users: users });
        //     });
    }

    private getViewMode(): OrganizationViewMode {        
        //return this.state.viewMode;
        switch (window.location.pathname.toLowerCase()) {
            case "/organization/users": return OrganizationViewMode.Users;
            case "/organization/reports": return OrganizationViewMode.Reports;
            case "/organization/devices": return OrganizationViewMode.Devices;
            case "/organization/resources": return OrganizationViewMode.Resources;
            case "/organization/subjectdetail": return OrganizationViewMode.SubjectDetail;
            default: return OrganizationViewMode.Subjects
        }
    }

    private BuildTitleContent() {
        return (<div className='space-evenly row'>
            {!this.state.userInfo?.CanViewPatients() ? null
                : (
                    <div className='col'>
                        <Link className={this.getTabClass(OrganizationViewMode.Subjects)} to={"/organization/subjects"} >
                            {F.getSubjectsLabel()}
                        </Link>
                    </div>
                )}
            {!this.state.userInfo?.CanViewUsersAndDevices() ? null : (
                <div className='col'>
                    <Link className={this.getTabClass(OrganizationViewMode.Users)} to={"/organization/users"} >
                        Users
                    </Link>
                </div>
            )}
            {!this.state.userInfo?.CanViewUsersAndDevices() ? null : (
                <div className='col'>
                    <Link className={this.getTabClass(OrganizationViewMode.Devices)} to={"/organization/devices"} >
                        Devices
                    </Link>
                </div>
            )}
                <div className='col'>
                    <Link className={this.getTabClass(OrganizationViewMode.Resources)} to={"/organization/resources"} >
                        Resources
                    </Link>
                </div>
        </div>);
    }

    private getTabClass(viewMode: OrganizationViewMode) {
        let currentViewMode = this.getViewMode();
        if (currentViewMode == OrganizationViewMode.SubjectDetail) {
            currentViewMode = OrganizationViewMode.Subjects;
        }
        return "tab  " + (currentViewMode == viewMode ? "selected" : "");
    }

    private BuildOrganizationHeader() {
        if (this.getViewMode() == OrganizationViewMode.SubjectDetail) {
            return <span></span>
        }
        return (
        <div className='organization-header'>
            <div className='label'>Organization:</div>
            <div>{this.state.org?.name}</div>
        </div>);
    }

    private BuildContent() {
        if (F.isNullOrWhitespace(this.props?.userInfo?.organizationId) && F.isNullOrWhitespace(this.props?.userInfo?.currentOrganizationId)) {
            return <Navigate to="/" />
        }
        switch (this.getViewMode()) {
            case OrganizationViewMode.Users: return this.buildUsersView();
            case OrganizationViewMode.Reports: return this.buildReportsView();
            case OrganizationViewMode.Devices: return this.buildDevicesView();
            case OrganizationViewMode.Resources: return this.buildResourcesView();
            case OrganizationViewMode.SubjectDetail: return this.buildSubjectDetailView();
            default: return this.buildPatientsView();
        }
    }

    private buildUsersView() {
        if (!this.state.org) {
            return "Loading Organization...";
        }
        if (!this.state.userInfo) {
            return "Loading User Info...";
        }
        return <OrganizationUsers org={this.state.org!!} userInfo={this.state.userInfo} onUsersUpdated={() => this.getSnapshot()} />
    }

    private buildReportsView() {
        return (<div>
            <div className='detail-header row d-none d-lg-flex'>
                <div className='col-12 col-md-4'>Reports</div>
                <div className='col-12 col-md-8'>

                </div>
            </div>
            <hr />
            <div className='detail-body col-sm-12'>
                List the available reports here
            </div>
        </div>
        );
    }

    private buildDevicesView() {
        if (!this.state.org) {
            return "Loading Organization";
        }
        if (!this.state.userInfo) {
            return "Loading User Info";
        }
        return <OrganizationDevices
            org={this.state.org}
            userInfo={this.state.userInfo}
            onDevicesUpdated={() => {
                this.getSnapshot();
            }}
            configuration={this.state.configuration}
            />
    }

    private buildResourcesView() {
        return <OrganizationResources />
    }

    private buildPatientsView() {
        return <OrganizationSubjects
            filter={this.patientFilter}
            onFilterChange={filter => this.onPatientFilterChange(filter)}
            userInfo={this.state.userInfo!}
            userToken={this.props.userToken}
            auth0={this.props.auth0}
            OrganizationId={this.state.org?.id??""}
            DocumentId={this.state.lastUpdatedSubjectId}
            UpdateType={vms.LiveUpdateType.Patient}
            LiveUpdateTrigger={this.state.liveUpdateTrigger}
            OnUpdateReceived={(event: vms.LiveUpdateEvent) => {
                if (event.UpdateType == vms.LiveUpdateType.Patient) {
                    this.setState({
                        liveUpdateTrigger: this.state.liveUpdateTrigger + 1,
                        lastUpdatedSubjectId: event.DocumentId,
                    })
                }
                else {
                    //console.log("Not updating the subject list", event);
                }
            }}
            />
    }

    private buildSubjectDetailView() {
        var id = window.location.search;
        if (id.length > 1) {
            id = id.substring(1);
        }
        return <SubjectDetail
            UpdateType={vms.LiveUpdateType.Patient}
            OrganizationId={this.state.org?.id ?? ""}
            patientId={id}
            DocumentId={id}
            userInfo={this.state.userInfo!}
            userToken={this.props.userToken}
            auth0={this.props.auth0}
            OnUpdateReceived={(event: vms.LiveUpdateEvent) => {
                if (event.UpdateType == vms.LiveUpdateType.Patient && event.DocumentId == id) {
                    this.setState({liveUpdateTrigger: this.state.liveUpdateTrigger + 1})
                }
                else {
                    //console.log("Not refreshing", event);
                }
            }}
            LiveUpdateTrigger={this.state.liveUpdateTrigger}
            />
    }

    private onPatientFilterChange(filter: string) {
        this.patientFilter = filter;
    }
}

export default Organization;
