import React, { useEffect, useState } from 'react';
import './DeviceCard.css';
import { useAuth0, withAuth0, WithAuth0Props } from '@auth0/auth0-react';
import * as vms from '../ViewModels';
import { Navigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import {ControllerActions} from "../ControllerActionHelpers/ControllerActions";
import { C } from '../Constants';
import { DeviceActions } from '../ControllerActionHelpers/DeviceActions';
import { F } from '../Functions';
import ApplicationModal, { ModalStyle } from '../ApplicationModal/ApplicationModal';
import ApplicationToast from '../ApplicationToast/ApplicationToast';
import { UserActions } from '../ControllerActionHelpers/UserActions';
import CreateUserForm from '../CreateUserForm/CreateUserForm';
import CreateDeviceForm from '../CreateDeviceForm/CreateDeviceForm';
import { OrganizationActions } from '../ControllerActionHelpers/OrganizationActions';
import MoveDeviceForm from '../MoveDeviceForm/MoveDeviceForm';
import LogFileDownloadCriteriaForm from '../LogFileDownloadCriteriaForm/LogFileDownloadCriteriaForm';
import { getLastFilename } from "../ControllerActionHelpers/httpHelper";
import LoadingWidget from '../LoadingWidget/LoadingWidget';
import { ModalDialog } from 'react-bootstrap';

interface DeviceCardState {
    device: vms.LicensedDevice;    
    configuration?: vms.IWebsiteConfiguration;
}

interface DeviceCardProps {
    device: vms.IDevice;
    onUpdated: { (): void };
    currentUser: vms.EyeBOXUser;
    configuration?: vms.IWebsiteConfiguration;
}

class DeviceCard extends React.Component<DeviceCardProps, DeviceCardState> {
    constructor(props: DeviceCardProps, state: DeviceCardState) {
        super(props);
        this.state = {
            device: new vms.LicensedDevice(props.device),
            configuration: props.configuration
        }
    }

    render() {
        return <div className='DeviceCard'>{this.BuildContent()}</div>
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }

    private BuildContent() {
        let device = this.state.device;
        return (
            <div>
                <div className='row no-gutters'>
                    <div className='col-2 col-md-1 middle' onClick={e => this.checkEditDevice(e)}>
                       <img src={this.getModelIcon(device.model)} alt={device.model} />
                    </div>
                    <div className='col-8 col-md-4' onClick={e => this.checkEditDevice(e)}>
                        {/* <div className='row'>
                            <div className='col-12 middle'>
                                {this.state.device.eyeBOXDeviceID}
                            </div>
                        </div> */}
                        {this.buildPropertyRow(1, "Device ID", this.state.device.eyeBOXDeviceID)}
                        {this.buildPropertyRow(2, "Model", this.state.device.model)}
                        {this.buildPropertyRow(3, "Serial", this.state.device.serialNumber)}
                    </div>
                    <div className='col-4 d-none d-md-block' onClick={e => this.checkEditDevice(e)}>
                        {this.buildPropertyRows()}
                    </div>
                    <div className='col-2 d-none d-md-block' onClick={e => this.checkEditDevice(e)}>
                    </div>
                    <div className='col-2 col-md-1 no-hand'>
                        {this.buildDeviceOptionsButton()}
                    </div>
                </div>
                <div className='d-md-none'>
                    {this.buildPropertyRows()}
                </div>
            </div>
        );
    }

    private getModelIcon(model?: string) : string {
        if (F.isNullOrWhitespace(model) || !this.state.configuration || !this.state.configuration.deviceModels || this.state.configuration.deviceModels.length == 0) {
            return "/unknown-model.png";
        }
        var filtered = this.state.configuration.deviceModels.filter((deviceModel, index, deviceModels) => {
            return model == deviceModel.name;
        });
        if (filtered.length == 1 && !F.isNullOrWhitespace(filtered[0].imageFilename)) {
            return "/" + filtered[0].imageFilename;
        }
        return "/unknown-model.png";
    }

    private buildPropertyRows(): Array<any> {
        let result: Array<any> = [];
        result.push(this.buildPropertyRow(1, "License Expires", F.getDateString(this.state.device.licenseExpiration)));
        result.push(this.buildPropertyRow(2, "Software", this.state.device.softwareVersion));
        result.push(this.buildPropertyRow(3, "In Service", F.getDateString(this.state.device.placedInService)));
        return result;
    }

    private buildPropertyRow(key: number, label: string, value?: string) {
        return (<div key={key} className='row no-gutters'>
            <div className='col-6 data-label'>{label}:</div>
            <div className='col-6 data-value'>{value}</div>
        </div>)
    }

    private buildDeviceOptionsButton() {
        if (!this.props.currentUser.HasRole(vms.EyeBOXUserRole.Super)) {
            return <div />
        }
        return (
        <div className='dropdown'>
          <a className="h-ellipses-button data-toggle"
            id="dropdownMenuButton"
            data-toggle="dropdown"
            aria-haspopup="true"
            >
            <span className='fa fa-ellipsis-h'></span>
          </a>
          <div className="dropdown-menu dropdown-menu-left" aria-labelledby="dropdownMenuButton">
            <div className='dropdown-list'>
              <div>
                <a className='rounded-button-small default-menu-item' onClick={e => {
                    this.editDevice();
                }}>
                    <span className='glyph-label'>
                        <span className='fa fa-up-right-from-square'></span>Edit
                    </span>                    
                </a>
              </div>
              {/* <div>
                <a className='rounded-button-small' onClick={e => {
                    this.removeDevice();
                 } }>
                    <span className='glyph-label'>
                        <span className='fa fa-xmark'></span>Remove
                    </span>                  
                </a>
              </div> */}
              <div>
                <a className='rounded-button-small' onClick={e => {
                    this.moveDevice();
                 } }>
                    <span className='glyph-label'>
                        <span className='fa fa-building-circle-arrow-right'></span>Move
                    </span>                  
                </a>
              </div>
              <div>
                <a className='rounded-button-small' onClick={e => {
                    this.getLogFiles();
                 } }>
                    <span className='glyph-label'>
                        <span className='fa fa-download'></span>Logs
                    </span>                  
                </a>
              </div>
              {
                F.isNullOrWhitespace(this.state.device.licenseCode)
                ? null
                : (
                    <div>
                    <a className='rounded-button-small' onClick={e => {
                        this.showLicenseCode()
                     } }>
                        <span className='glyph-label'>
                            <span className='fa fa-barcode'></span>License
                        </span>                  
                    </a>
                  </div>
                )
              }
            </div>
          </div>
        </div>
        );
    }

    private showLicenseCode() {
        ApplicationModal.showMessage(this.state.device.licenseCode!, ModalStyle.Information, this.state.device.eyeBOXDeviceID + " License");
    }

    private async checkEditDevice(e: React.MouseEvent<HTMLDivElement>) {
        if (!this.props.currentUser.HasRole(vms.EyeBOXUserRole.Super)) {
            return;
        }
        if ($(e.target).hasClass("fa-ellipsis-h") || $(e.target).hasClass("glyph-label")) {
            return;
        }
        this.editDevice();
    }

    private canEditDevices(): boolean {
        return this.props.currentUser.HasRole(vms.EyeBOXUserRole.Super);
    }

    private async editDevice() {
        if (!this.canEditDevices()) {
            ApplicationToast.Show("You are not authorized to edit devices.");
            return;
        }
        var form: any;
        var update = await ApplicationModal.showForm(<CreateDeviceForm
            ref={e => { form = e ?? form; }}
            initialValues={this.state.device}
            isCreating={false}
            configuration={this.state.configuration}
        />, "Edit Device", undefined, "SAVE");
        if (update) {
            var updatedDevice: vms.LicensedDevice = form.state.device;
            await DeviceActions.update(updatedDevice)
                .then(updatedDeviceResult => {
                    if (updatedDeviceResult) {
                        this.setState({
                            device: new vms.LicensedDevice(updatedDeviceResult)
                        });
                        this.props.onUpdated();
                    }
                })
                .catch(ControllerActions.reportError);
        }
    }

    private async removeDevice() {
        if (!this.state.device.eyeBOXDeviceID) {
            ApplicationToast.Show("This device's EyeBOXDeviceID property is missing a value.");
            return;
        }
        if (!this.canEditDevices()) {
            ApplicationToast.Show("You are not authorized to remove devices.");
            return;
        }
        let doDelete = await ApplicationModal.confirm("Remove device '" + this.state.device.serialNumber + "'?", true);
        if (!doDelete) {
            return;
        }
        ApplicationToast.Show("Removing device '" + this.state.device.serialNumber + "'");
        let moved = await DeviceActions.move(this.state.device.eyeBOXDeviceID, C.Unassigned, 0);
        if (moved) {
            ApplicationToast.Show("Successfully removed device '" + this.state.device.serialNumber + "'");
            this.props.onUpdated();
        }
        else {
            ApplicationToast.Show("Error encountered while removing device '" + this.state.device.serialNumber + "'");
        }
    }

    private async moveDevice() {
        if (!this.canEditDevices()) {
            ApplicationToast.Show("You are not authorized to move devices.");
            return;
        }
        if (!this.state.device.eyeBOXDeviceID) {
            ApplicationToast.Show("The current device does not have an EyeBOXDeviceID.")
            return;
        }
        let organizationNames = await OrganizationActions.enumerateNames();
        let form: any;
        let move = await ApplicationModal.showForm(<MoveDeviceForm
            ref={e => { form = e ?? form; }}
            organizations={organizationNames}
            originalOrgId={this.state.device.organizationId}
        />, "Move Device", undefined, "Move");
        if (move) {
            if (this.state.device.organizationId == form.state.newOrgId) {
                ApplicationToast.Show("The device is already associated with the selected organization.");
                return;
            }
            let maxAgeDays = Number.parseInt(form.state.maxAgeDays);

            if (maxAgeDays > 0) {
                //we are moving patients, too, so it might take some time
                let subjectsLabel = F.getSubjectsLabel();
                ApplicationModal.showDialog({
                    title: `Moving Device and ${subjectsLabel}`,
                    message: `Please wait while the Device and ${subjectsLabel} are moved.`,
                    actions: [],
                    style: ModalStyle.Spin,
                });
            }
    
            await DeviceActions.move(this.state.device.eyeBOXDeviceID, form.state.newOrgId, maxAgeDays)
                .then(moveResponse => {
                    if (moveResponse) {
                        this.props.onUpdated();                        
                        ApplicationToast.Show("Device was successfully moved.");
                        if (form.state.maxAgeDays > 0 && moveResponse.patientsMoved > 0) {
                            ApplicationToast.Show(`Moved ${F.pluralize(moveResponse.patientsMoved, F.getSubjectLabel().toLowerCase())}`);
                        }
                    }
                    ApplicationModal.close();
                })
                .catch(async error => {
                    await ApplicationModal.close();
                    ControllerActions.reportError(error);                    
                });
        }
    }

    private async getLogFiles() {
        if (!this.state.device.model) {
            ApplicationModal.showError("A device model must be assigned before logs can be retrieved.");
            return;
        }
        if (!this.state.device.eyeBOXDeviceID) {
            ApplicationModal.showError("A device ID must be assigned before logs can be retrieved.");
            return;
        }
        let deviceId = this.state.device.eyeBOXDeviceID;
        let simpleModel = "";
        if (this.state.configuration?.deviceModels) {
            for (let model of this.state.configuration?.deviceModels) {
                if (model.name == this.state.device.model) {
                    simpleModel = model.simpleModel;
                    break;
                }
            }
        }
        //debug overrides
        // simpleModel = "dmr-0004";
        // deviceId = "TT4104-b4e273ab";
        if (F.isNullOrWhitespace(simpleModel)) {
            ApplicationModal.showError("The log folder for model " + this.state.device.model + " could not be determined.");
            return;
        }
        var form: any;
        var doExport = await ApplicationModal.showForm(<LogFileDownloadCriteriaForm
            ref={e => { form = e ?? form; }}
            deviceModel={simpleModel || ""}
            deviceSerial={deviceId || ""}
        />, "Download Log Files", (form) => {
            return LogFileDownloadCriteriaForm.OnValidate(form);
        });
        if (doExport) {
            const abortController = new AbortController();
            let spinTimeout = setTimeout(() => {
                ApplicationModal.showCancelableSpin("Retrieving log files...", abortController);
            }, 500);
            await OrganizationActions.getLogFiles(form.state, abortController.signal)
            .then(file => {
                var url = URL.createObjectURL(file);
                let a = document.createElement("a") as HTMLAnchorElement;
                a.href = url;
                a.download = getLastFilename();
                a.click();
                URL.revokeObjectURL(url);
                clearTimeout(spinTimeout);
                ApplicationModal.close();
            })
            .catch(e => {
                if (e == ApplicationModal.AbortDownloadReason) {
                    return;
                }
                clearTimeout(spinTimeout);
                ApplicationModal.close();
                ControllerActions.reportError(e);
            } );
        }
    }

}

export default DeviceCard;
