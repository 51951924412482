import React, { useEffect, useState } from 'react';
import Form from "react-bootstrap/Form"
import { F } from '../Functions';
import './DialogFormBase.css';

export interface IOptionData {
    text: string;
    value: string;
}

abstract class DialogFormBase<P, S> extends React.Component<P, S> {
    constructor(props: P) {
        super(props);
    }

    protected buildStandardInput(id: string, label: string, value: string | undefined,
        onChange: { (e: React.ChangeEvent<HTMLInputElement>): void },
        includeLineBreak?: boolean,
        onCheckRequired?: { (): boolean },
        pattern?: string,
        isReadonly?: boolean,
        placeholder?: string,
        isPassword?: boolean,
        needPasswordInsight?: boolean) {
        return (
            <div key={id} className={(needPasswordInsight ? "password-container" : "")}>
                {
                    F.isNullOrWhitespace(label)
                    ? null
                    : <label htmlFor={id}>{label}</label>
                }
                <input
                    type={(isPassword ? "password" : undefined)}
                    required={(typeof(onCheckRequired) == "function") ? onCheckRequired() : true}
                    id={id}
                    name={id}
                    value={value ?? ""}
                    className={'full-width form-control' + (F.isNullOrWhitespace(label) ? " indented" : "")}
                    onChange={e => { onChange(e); }}
                    onInput={e => {
                        const input = e.target as HTMLInputElement;
                        if (needPasswordInsight) {
                            const password = input.value;
                            const lengthReq = password.length >= 8;                            
                            const hasLowerCase = /[a-z]/.test(password);
                            const hasUpperCase = /[A-Z]/.test(password);
                            const hasNumber = /\d/.test(password);
                            const hasSpecialChar = /[!@#$%^&*]/.test(password);
                            const criteriaMet = [hasLowerCase, hasUpperCase, hasNumber, hasSpecialChar].filter(Boolean).length;
                        
                            // Update UI classes based on validation
                            document.getElementById('length-req')?.classList.toggle('valid', lengthReq);
                            document.getElementById('three-of-four-req')?.classList.toggle('valid', criteriaMet >= 3);
                            document.getElementById('lowercase-req')?.classList.toggle('valid', hasLowerCase);
                            document.getElementById('uppercase-req')?.classList.toggle('valid', hasUpperCase);
                            document.getElementById('number-req')?.classList.toggle('valid', hasNumber);
                            document.getElementById('specialchar-req')?.classList.toggle('valid', hasSpecialChar);
                        }
                    }}
                    pattern={pattern}
                    readOnly={isReadonly || false}
                    placeholder={placeholder}
                    maxLength={placeholder ? placeholder.length : undefined}
                    />
                {this.buildValidationMessage(id, label)}
                {includeLineBreak ? <br /> : null}
                {
                    needPasswordInsight
                    ? (
                        <div id="password-requirements" className="password-requirements">
                            <p className="requirement major" id="length-req">At least 8 characters in length</p>
                            <p className='requirement major' id="three-of-four-req">Three of the following:</p>
                            <p className="requirement" id="lowercase-req">Lowercase letters (a-z)</p>
                            <p className="requirement" id="uppercase-req">Uppercase letters (A-Z)</p>
                            <p className="requirement" id="number-req">Numbers (0-9)</p>
                            <p className="requirement" id="specialchar-req">
                                Special characters
                                <br/>
                                <span className='indented'>
                                    (e.g., !@#$%^&*)
                                </span>
                            </p>
                        </div>
                    )
                    : null
                }
            </div>
        )
    }

    protected buildStandardPhoneInput(id: string, label: string, value: string | undefined,
        onChange: { (newValue: string): void },
        includeLineBreak?: boolean, onCheckRequired?: { (): boolean }, isReadonly?: boolean) {
            const formatPhoneNumber = (input: string) => {
                // Remove all non-digit characters
                input = input.replace(/\D/g, '');

                // Format the input as ###-###-####
                return input.length < 6
                    ? input.replace(/(\d{3})(\d{0,3})/, '$1-$2')
                    : input.replace(/(\d{3})(\d{3})(\d{0,4})/, '$1-$2-$3');                
            };

            const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                // Format the input value
                const formattedValue = formatPhoneNumber(event.target.value);
                // Call the onChange prop with the formatted value
                onChange(formattedValue);
            };

            const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
                if (event.key === 'Backspace') {
                    var inputElement = event.target as HTMLInputElement;

                  const cursorPosition = inputElement.selectionStart ?? 0;
                  // Check if the cursor is right after a hyphen and prevent the default backspace behavior
                  if (cursorPosition > 0 && value![cursorPosition - 1] === '-') {
                    event.preventDefault();
                    const newValue = value!.substring(0, cursorPosition - 1) + value!.substring(cursorPosition);
                    onChange(newValue);
                  }
                }
              };

            let result = this.buildStandardInput(id, label, value, handleInputChange, includeLineBreak, onCheckRequired,
                "[0-9]{3}-[0-9]{3}-[0-9]{4}", false, "###-###-####");

            return React.cloneElement(result, {
                onKeyDown: handleKeyDown
            });
    }

    protected buildStandardDatePicker(id: string, label: string, value: Date | undefined,
        onChange: { (e: React.ChangeEvent<HTMLInputElement>): void },
        includeLineBreak?: boolean,
        onCheckRequired?: { (): boolean },
        isReadonly?: boolean) {
        if (typeof(value) == "string") {
            value = new Date(value);
        }
        return (
            <div key={id}>
                <label htmlFor={id}>{label}</label>
                <Form.Control
                    type='date'
                    required={(typeof(onCheckRequired) == "function") ? onCheckRequired() : true}
                    id={id}
                    name={id}
                    defaultValue={this.tryGetDateString(value)}
                    //value={this.tryGetDateString(value)}
                    className='full-width form-control'
                    onChange={onChange}
                    readOnly={isReadonly || false}
                    //pattern='MM/dd/yyyy'
                    />
                {this.buildValidationMessage(id, label)}
                {includeLineBreak ? <br /> : null}
            </div>
        )
    }

    private tryGetDateString(dt?: Date): string {
        if (!dt) {
            return "";
        }
        //need yyyy-mm-dd for the date picker to initialize correctly
        return F.getDateString(dt, true);
    }

    protected buildStandardTextArea(id: string, label: string, value: string | undefined, rows: number,
        onChange: { (e: React.ChangeEvent<HTMLTextAreaElement>): void },
        includeLineBreak?: boolean,
        onCheckRequired?: { (): boolean }) {
        return (
            <div key={id}>
                <label htmlFor={id}>{label}</label>
                <textarea
                    required={(typeof(onCheckRequired) == "function") ? onCheckRequired() : true}
                    id={id}
                    name={id}
                    value={value ?? ""}
                    rows={rows}
                    className='full-width form-control'
                    onChange={onChange} />
                {this.buildValidationMessage(id, label)}
                {includeLineBreak ? <br /> : null}
            </div>
        )
    }

    protected buildStandardSelect(id: string, label: string, value: string | undefined, options: Array<IOptionData>, onChange: { (e: React.ChangeEvent<HTMLSelectElement>): void }, includeLineBreak?: boolean) {
        return (
            <div key={id}>
                <label htmlFor={id}>{label}</label>
                <select required className='full-width form-control' id={id} value={value ?? ""} onChange={onChange}>
                    {
                        options.map((item, index, items) => {
                            return <option key={index} value={item.value}>{item.text}</option>
                        })
                    }
                </select>
                {this.buildValidationMessage(id, label)}
                {includeLineBreak ? <br /> : null}
            </div>
        )
    }

    protected buildStandardOption(value: string, text?: string ): IOptionData {
        return {
            value,
            text: text ?? value
        };
    }

    protected buildStandardCheckbox(id: string, label: string, value: boolean | undefined,
        onChange: { (e: React.ChangeEvent<HTMLInputElement>): void },
        includeLineBreak?: boolean,
        isReadonly?: boolean) {
        return (
            <div key={id} style={{marginBottom: "5px"}}>
                <input id={id} type="checkbox" checked={value} onChange={onChange}></input>
                <label htmlFor={id}>&nbsp;&nbsp;{label}</label>
                {includeLineBreak ? <br /> : null}
            </div>
        );
    }

    protected buildValidationMessage(controlName: string, labelText: string) {
        return <div val-msg-for={controlName} className="validation-ok validation-error">{"Please enter a value for " + labelText}</div>
    }
    public someMethod() {

    }
}

export default DialogFormBase;
