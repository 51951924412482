import { WithAuth0Props } from '@auth0/auth0-react';
import { F } from './Functions';

export enum OrganizationType {
    Clinic = 1, ED = 2, PI = 3, Research = 4, Other = 99
}

export enum ContentTypes
{
   Log = 1,          // .log
   Meta = 2,         // .xml
   Metrics = 3,      // .csv
   PDF = 4,          // .pdf
   EyeBOXScan = 5,   // .ord
   SRScan = 6,       // .edf
   Image = 7,        // .png
   Report = 8,       // .orpt or .json
   Form = 9,         // .form
   Comments = 10,    // comments translated into a csv
   Video = 11,       // .mp4
   Other = 99        // anything else
}

export interface IApplicationError {
    isApplicationError: boolean;
    message: string;
    isUserError: boolean;
}

export interface IFileResponse {
    url: string;
    contentType: string;
    fileName: string;
}

export interface IViewModel {
    id: string;
}

export interface IDeviceModelInfo {
    name: string;
    simpleModel: string;
    imageFilename: string;
}

export interface IWebsiteConfiguration extends IViewModel {
    domain: string;
    appName: string;
    primaryMessage: string;
    secondaryMessages: Array<string>;
    deviceModels: Array<IDeviceModelInfo>;
}

export interface INews extends IViewModel {
    title: string;
    body: string;
    linkLabel: string;
    linkUrl: string;
    organizationId?: string;
    dateCreated?: Date;
    isPublic: boolean;
}

export interface INewsReceipt extends IViewModel {
    email: string;
    newsId: string;
}

//IScanComment does not inherit from IViewModel because the id can be null
export interface IScanComment {
    id?: string;
    comment: string;
    commentDate?: Date;
    contributorEmail?: string;
    contributorName?: string;
}

export class EditableScanComment implements IScanComment {
    id?: string;
    comment: string;
    commentDate?: Date;
    contributorEmail?: string;
    originalComment: string;

    constructor(comment: string) {
        this.comment = comment;
        this.originalComment = comment;
    }

    public static fromInterface(source: IScanComment): EditableScanComment {
        let result = new EditableScanComment(source.comment);
        result.id = source.id;
        result.commentDate = source.commentDate;
        result.contributorEmail = source.contributorEmail;
        return result;
    }

    public static fromObject(source: EditableScanComment) {
        let result = this.fromInterface(source);
        result.originalComment = source.originalComment;
        return result;
    }

    public isDirty() {
        return this.comment != this.originalComment;
    }
}

export enum Normality {
    Normal = 0,
    Indeterminant = 1,
    Abnormal = 2,
    Undefined = 99,
}

export enum Application {
    Concussion = 0,
    Cannabis = 1,
    Undefined = 99,
}

export class ScanTypes {
    cannabis: boolean = false;
    concussion: boolean = false;
    public isAll() {
        return this.cannabis && this.concussion;
    }
}

export interface IScan extends IViewModel {
    scanDateTime: Date;
    scanID: string;
    uploadStatus: number;
    uploadDateTime: Date;
    deviceId: string;
    deviceModel: string;
    userName: string;
    patientId: string;
    box?: number;
    cannabis?: number;
    normality: Normality;
    application: Application;
    softwareMajorVersion: number;
    softwareMinorVersion: number;
    softwareBuild: number;
    postProcessingOptions: string;
    notes: string;
    files: Array<IFileReference>;
    comments: Array<IScanComment>;
    completeUpload: boolean;
}

export enum FileSystemTypes {None = 0, Local = 1, Box = 2, Blob = 4 };

export interface IFileReference extends IViewModel {
    folderPath: string;
    fileName: string;
    contentType: ContentTypes;
    fileSystemType: FileSystemTypes;
    createdDateTime?: Date;
    modifiedDateTime?: Date;
    size: number;
    uri: string;
}

export interface IForm extends IViewModel {
    formDateTime: Date;
    formName: string;
    notes: string;
    json: string;
    responses: Array<IFormResponse>;
}

export interface IFormResponse extends IViewModel {
    name: string;
    label: string;
    value: string;
}

export interface ISession extends IViewModel {
    patientId: string;
    operatorId: string;
    sessionDateTime: Date;
    location: string;
    notes: string;
    forms?: Array<IForm>;
    scans?: Array<IScan>;
}

export interface IPatient extends IViewModel {
    organizationId?: string;
    eyeBOXPatientId: string;
    guid?: string;
    firstName: string;
    middleInitial: string;
    lastName: string;
    dob?: Date;
    yob?: number;
    gender: string;
    age?: number;
    altId1: string;
    altId2: string;
    sessions: Array<ISession>;
    lastScanDate?: Date;
}

export interface IFilteredCollectionResponse<T> {
    records: Array<T>;
    available: number;
    startIndex: number;
    endIndex: number;
    filter: string;
}

export interface IPatientResponse extends IFilteredCollectionResponse<IPatient> {
}

export interface IOrganizationResponse extends IFilteredCollectionResponse<IOrganization> {
}

export interface IOrganization extends IViewModel {
    name?: string;
    creationDate?: Date;
    type?: OrganizationType;
    devices?: Array<IDevice>;
    users?: Array<IEyeBOXUser>;
    contactName?: string;
    contactEMail?: string;
    contactPhone?: string;
    notes?: string;
    patientCount?: number;
    isEnabled: boolean;
}

export interface IOrganizationBrief extends IViewModel {
    name?: string;
}

export interface IOrganizationChild {
    organizationId?: string;
}

export interface IDevice extends IOrganizationChild {
    model?: string;
    serialNumber?: string;
    eyeBOXDeviceID?: string;
    placedInService?: Date;
    removedFromService?: Date;
    softwareVersion?: string;
    licenseExpiration?: Date;
    licenseCode?: string;
    licenseOptions?: number;
}

export class LicensedDevice implements IDevice, IOrganizationChild {
    organizationId?: string;
    model?: string;
    serialNumber?: string;
    eyeBOXDeviceID?: string;
    placedInService?: Date;
    removedFromService?: Date;
    softwareVersion?: string;
    licenseExpiration?: Date;
    licenseCode?: string;
    licenseOptions?: number;
    //features
    allowExportOfMetrics?: boolean;
    allowOfflineLicensing?: boolean;

    constructor(device: IDevice) {
        Object.assign(this, device);
        if (!this.licenseOptions) {
            this.licenseOptions = 0;
        }
        this.allowExportOfMetrics = this.GetFeatureEnabled(LicenseFeatures.AllowExportOfMetrics);
        this.allowOfflineLicensing = this.GetFeatureEnabled(LicenseFeatures.AllowOfflineLicensing);
    }

    //called in response to JSON.stringify
    toJSON() {
        this.buildOptionsFromFeatures();
        //cast to IDevice to drop the feature flags
        return this;
    }

    private GetFeatureEnabled(feature: LicenseFeatures): boolean {
        let featureValue = 1 << feature;
        return ((this.licenseOptions ?? 0) & featureValue) !== 0;
    }

    public buildOptionsFromFeatures() {
        //temporarily clear all bits
        this.licenseOptions = 0;
        //set each feature value bit
        this.SetFeatureEnabled(LicenseFeatures.AllowExportOfMetrics, this.allowExportOfMetrics);
        this.SetFeatureEnabled(LicenseFeatures.AllowOfflineLicensing, this.allowOfflineLicensing);
    }

    private SetFeatureEnabled(feature: LicenseFeatures, value?: boolean): void {
        const featureValue = 1 << feature;
        
        if (value) {
            // Set the bit using OR
            this.licenseOptions = (this.licenseOptions ?? 0) | featureValue;
        } else {
            // Clear the bit using AND with inverted mask 
            this.licenseOptions = (this.licenseOptions ?? 0) & ~featureValue;
        }
     }
}

export enum LicenseFeatures {
    AllowExportOfMetrics = 0,
    AllowOfflineLicensing = 1,
    //include more features when available
}

export class EyeBOXUserRole {
    public static readonly Normal = "Normal";
    public static readonly Admin = "Admin";
    public static readonly Super = "Super";
    public static readonly Contributor = "Contributor";
    public static readonly Any = "Any";

    public static getDescription(role: string): string {
        switch (role) {
            case EyeBOXUserRole.Normal: return "Normal (view patients and reports)";
            case EyeBOXUserRole.Contributor: return "Contributor (add/edit report comments)";
            case EyeBOXUserRole.Admin: return "Organization Admin (add/edit users)";
            case EyeBOXUserRole.Super: return "Super Admin (Oculogica staff only)";
            default: return role;
        }
    }
}

export class NotificationType {
    public static readonly Email = "Email";
    public static readonly Sms = "Sms";
}

export interface IEyeBOXUser extends IOrganizationChild {
    email?: string;
    userId?: string;
    name?: string;
    roles?: Array<string>;
    lastLogin?: Date;
    //impersonateAsEmail?: string;
    currentOrganizationId?: string;
    notificationTypes?: Array<string>;
    phone?: string;
    enabled: boolean;
    stoppedViaSms: boolean;
    unsubscribeOTP?: string;
    confirmedProfile: boolean;
}

export interface AuthenticatedUserProps extends WithAuth0Props {
    userInfo: IEyeBOXUser;
    userToken: string;
}

export class EyeBOXUser implements IEyeBOXUser {
    organizationId?: string;
    email?: string;
    userId?: string;
    name?: string;
    roles?: Array<string>;
    lastLogin?: Date;
    //impersonateAsEmail?: string;
    currentOrganizationId?: string;
    notificationTypes?: Array<string>;
    phone?: string;
    enabled: boolean;
    stoppedViaSms: boolean;
    unsubscribeOTP?: string;
    confirmedProfile: boolean;

    constructor(source?: IEyeBOXUser) {
        if (null == source) {
            throw new ReferenceError("A valid user must be provided");
        }
        this.organizationId = source.organizationId;
        this.email = source.email;
        this.userId = source.userId;
        this.name = source.name;
        this.roles = F.clone(source.roles) ?? [];
        this.lastLogin = source.lastLogin;
        //this.impersonateAsEmail = source.impersonateAsEmail;
        this.currentOrganizationId = source.currentOrganizationId;
        this.notificationTypes = F.clone(source.notificationTypes) ?? [];
        this.phone = source.phone;
        this.enabled = source.enabled;
        this.stoppedViaSms = source.stoppedViaSms;
        this.unsubscribeOTP = source.unsubscribeOTP;
        this.confirmedProfile = source.confirmedProfile;
    }

    public HasRole(...roles: Array<string>): boolean {
        if (roles == null || roles.length == 0) {
            return false;
        }
        if (this.roles == null || this.roles.length == 0) {
            return false;
        }
        if (roles.indexOf(EyeBOXUserRole.Any) != -1) {
            return true;
        }
        for (var includedRole of this.roles) {
            for (var testedRole of roles) {
                if (includedRole == testedRole) {
                    return true;
                }
            }
        }
        return false;
    }

    public HasNotificationType(notificationType: string): boolean {
        if (F.isNullOrWhitespace(notificationType)) {
            return false;
        }
        if (this.notificationTypes == null || this.notificationTypes.length == 0) {
            return false;
        }
        return this.notificationTypes.indexOf(notificationType) != -1;
    }

    public CanViewPatients(): boolean {
        return this.enabled && this.HasRole(EyeBOXUserRole.Normal, EyeBOXUserRole.Contributor);
    }

    public CanViewUsersAndDevices(): boolean {
        return this.enabled && this.HasRole(EyeBOXUserRole.Admin);
    }

    public CanUpdateUsers(): boolean {
        return this.enabled && this.HasRole(EyeBOXUserRole.Admin, EyeBOXUserRole.Super);
    }
}

export enum CloudResourceItemType {
    Unknown = 0,
    Folder = 1,
    PDF = 2,
    Word = 3,
    Bookmark = 4,
}

export interface ICloudResourceItem {
    type: CloudResourceItemType;
    name: string;
    path: string;
    items: Array<ICloudResourceItem>;
}

export interface IDataExportCriteria {
    exportName?: string;
    startDate?: Date;
    endDate?: Date;    
    includeMetrics: boolean;
}

export interface IUsageLogDownloadRequest {
    startDate?: Date;
    endDate?: Date;    
    deviceModel: string;
    deviceSerial: string;
}

export interface IUpdatePasswordRequest {
    email: string;
    resetToken?: string;
    currentPassword?: string;
    newPassword?: string;
}

export interface IUpdatePasswordResponse {
    message: string;
    success: boolean;
}

export interface IMoveDeviceResponse {
    patientsMoved: number;
}

export enum LiveUpdateType {
    // Keep this in sync with the C# LiveUpdateType enum
    Unknown = 0,
    Patient = 1,
    Organization = 2,
}

export interface LiveUpdateEvent {
    UpdateType: LiveUpdateType;
    OrganizationId: string;
    DocumentId: string;
    Timestamp: string;
    //Metadata?: Record<string, any>;
}

export interface LiveUpdateProps extends AuthenticatedUserProps {
    UpdateType: LiveUpdateType;
    OrganizationId: string;
    DocumentId: string;
    LiveUpdateTrigger: number;
    //Metadata?: Record<string, any>;
    OnUpdateReceived?: (event: LiveUpdateEvent) => void;
}